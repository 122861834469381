import React, { useEffect } from 'react';
import './App.css';
import { Layout } from './components/Layout/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { fetchGalleryImages } from './store/galleryImages/actions';

function App({ onFetchImages }: { onFetchImages: () => Promise<void> }) {
  useEffect(() => {
    onFetchImages();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Router>
        <Layout />
      </Router>
    </>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchImages: () => dispatch(fetchGalleryImages()),
});
export default connect(null, mapDispatchToProps)(App);
