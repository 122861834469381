import { GalleyImagesActionTypes } from '../types';
import { IImagesDate } from '../../../services/PageSvc';

export interface iGalleryImagesState {
  images: IImagesDate;
  filteredImages: IImagesDate;
  isLoading: boolean;
  isError: boolean;
}

const emptyImages: IImagesDate = {
  images: [],
  thumbnails: [],
};

const initialValue: iGalleryImagesState = {
  images: { ...emptyImages },
  filteredImages: { ...emptyImages },
  isLoading: true,
  isError: false,
};

const errorValue: iGalleryImagesState = {
  ...initialValue,
  isLoading: false,
  isError: true,
};

export const galleryImagesReducer = (
  state = initialValue,
  action: GalleyImagesActionTypes
): iGalleryImagesState => {
  switch (action.type) {
    case 'FETCH_IMAGES':
      return { ...state, ...initialValue };
    case 'FETCH_IMAGES_SUCCESS':
      return { ...state, isLoading: false, isError: false, images: action.payload };
    case 'FETCH_IMAGES_ERROR':
      return { ...state, ...errorValue };
    default:
      return state;
  }
};
