import axios from 'axios';

import { TSideBarLink } from '../components/SideBar/SideBar';
import { ICategory } from '../models/ICategory';
import { IPage } from '../models/IPage';
import { pages } from './mocks/pagesMock';
import { contact } from './mocks/contactMock';

export type TCategoryLink = {
  title: string;
  url: string;
};

export interface IContact {
  address: {
    street: string;
    district: string;
    city: string;
    zip: string;
  };
  phones: string[];
  email: string;
  companyName: string;
}

export interface IPhoto {
  tags: string[];
  isThumbnail: boolean;
  url: string;
  name: string;
  geo: string;
  siblingCode: string;
  partType: string;
  material: string;
}

export interface IImagesDate {
  images: IPhoto[];
  thumbnails: IPhoto[];
}

export class PageSvc {
  static async getPhotosData(): Promise<IImagesDate | undefined> {
    let images: IPhoto[] = [];
    let thumbnails: IPhoto[] = [];
    try {
      const { data } = await axios.get('http://tkswindowsanddoors.co.uk/photos/');
      images = data.images.filter((image: IPhoto) => !image.isThumbnail);
      thumbnails = data.images.filter((image: IPhoto) => image.isThumbnail);

      return { images, thumbnails };
    } catch (error) {
      console.log(error);
    }
  }

  static getCategory(categoryUrl: string): ICategory | undefined {
    return pages.find((cat) => cat.url.toLowerCase() === categoryUrl.toLowerCase());
  }
  static getAllCategoryLinks(): TCategoryLink[] {
    return pages.map((page) => ({ title: page.category, url: page.url }));
  }
  static getSubPage(categoryUrl: string, subPageUrl: string): IPage | undefined {
    return PageSvc.getCategory(categoryUrl)?.subPages.find(
      (page) => page.url === subPageUrl
    );
  }
  static getSideBarLinks(categoryUrl: string): TSideBarLink[] | null {
    const category = PageSvc.getCategory(categoryUrl);
    if (!category) return null;
    const sideBarLinks = category.subPages.reduce<TCategoryLink[]>((linksArray, page) => {
      linksArray.push({
        title: page.title,
        url: `/${categoryUrl}/${page.url}`,
      });
      return linksArray;
    }, []);
    return sideBarLinks[0].title !== '' ? sideBarLinks : null;
  }

  static getContactProps<K extends keyof typeof contact>(propName: K): typeof contact[K] {
    return contact[propName];
  }

  static getContactData(): typeof contact {
    return contact;
  }
}
