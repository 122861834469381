export const photosOrder = [
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/window4.jpeg',
    on: -5,
    order: -6,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/window2.jpeg',
    on: -4,
    order: -5,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/window1.jpeg',
    on: -3,
    order: -4,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/hardwood sash window system.jpeg',
    on: -2,
    order: -3,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/hardwood sash window.jpeg',
    on: -1,
    order: -2,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Custom multi sash window front.jpeg',
    on: 0,
    order: -1,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Custom multi sash window.jpeg',
    on: 3,
    order: 0,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Single sash window front.jpeg',
    on: 11,
    order: 1,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Pine Sash windows, SE15 (2).jpeg',
    on: 15,
    order: 2,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Hardwood Sash windows, SE14.JPG',
    on: 12,
    order: 3,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Pine Sash windows, SE15.jpeg',
    on: 5,
    order: 4,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Hardwood Sash window, SE14 (3).JPG',
    on: 7,
    order: 5,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Pine Sash windows, SE4.jpeg',
    on: 1,
    order: 6,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Hardwood Sash windows and door, SE14.JPG',
    on: 16,
    order: 7,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Bay window, Hardwood Sash windows, SE14.JPG',
    on: 9,
    order: 8,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Custom multi sash window inside.jpeg',
    on: 14,
    order: 9,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Hardwood Sash window, SE14 (4).JPG',
    on: 2,
    order: 10,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Bay windows, Hardwood Sash window, SE14 (2).JPG',
    on: 4,
    order: 11,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Hardwood privacy Sash window, SE14.JPG',
    on: 13,
    order: 12,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Bay windows, Hardwood Sash windows, SE14.JPG',
    on: 10,
    order: 13,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Bay windows, Hardwood Sash window, SE14.JPG',
    on: 6,
    order: 14,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/window3.jpeg',
    on: 15,
    order: 15,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Hardwood Sash window, SE14 (2).JPG',
    on: 8,
    order: 16,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Hardwood Sash window, SE14.JPG',
    on: 17,
    order: 17,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Sash window, 1.jpg',
    on: 18,
    order: 18,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Sash window, 2.jpg',
    on: 21,
    order: 19,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Sash window, 3.jpg',
    on: 24,
    order: 20,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Sash window, 4.jpg',
    on: 19,
    order: 21,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Sash window, 5.jpg',
    on: 22,
    order: 22,
  },
  // {
  //   url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Sash window 1.jpg',
  //   on: 23,
  //   order: 23,
  // },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/wood/thumbnail/Sash window, 6.jpg',
    on: 20,
    order: 24,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/wood/thumbnail/Hardwood French door, Se4.JPG',
    on: 25,
    order: 25,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/wood/thumbnail/Hardwood panel French door, SE14.JPG',
    on: 26,
    order: 26,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/wood/thumbnail/Hardwood French door, SE14.JPG',
    on: 27,
    order: 27,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/wood/thumbnail/3 bolt multi point locking system, Se4 (2).jpeg',
    on: 29,
    order: 28,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/wood/thumbnail/Hardwood French door, SE15.jpeg',
    on: 28,
    order: 29,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/wood/thumbnail/Hardwood French door, Se4.jpeg',
    on: 30,
    order: 30,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/wood/thumbnail/FRENCH DOORS PINE DOUBLE GLAZED.jpeg',
    on: 31,
    order: 31,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/aluminium/thumbnail/Aluminium pivot window, SE14.JPG',
    on: 34,
    order: 32,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/aluminium/thumbnail/ORIAL window, SE15.jpeg',
    on: 32,
    order: 33,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/aluminium/thumbnail/Aluminium bay windows, SE15.jpeg',
    on: 33,
    order: 34,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/aluminium/thumbnail/Aluminium windows, SE15.jpeg',
    on: 31,
    order: 35,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/windows/aluminium/thumbnail/Aluminium window, privacy glass, SE15.jpeg',
    on: 35,
    order: 36,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Aluminium Bi-fold doors, SE14.JPG',
    on: 36,
    order: 37,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Black modern door.jpeg',
    on: 37,
    order: 38,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Aluminium sliding doors, SE14.JPG',
    on: 38,
    order: 39,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Black door.jpeg',
    on: 39,
    order: 40,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Aluminium Bi-fold doors, SE14 (2).JPG',
    on: 40,
    order: 41,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Aluminium Bi-fold doors, SE13.JPG',
    on: 41,
    order: 42,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Lift&slide door, tripple glased, SE15.jpeg',
    on: 42,
    order: 43,
  },
  {
    url: 'http://tkswindowsanddoors.co.uk/photos/doors/aluminium/thumbnail/Aluminium Bi-fold doors Adelide Rd, SE4.jpeg',
    on: 43,
    order: 44,
  },
];
