import { ActionCreator, CombinedState, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IImagesDate, IPhoto, PageSvc } from '../../../services/PageSvc';
import { iGalleryImagesState } from '../reducers';
import {
  FetchImagesSuccessAction,
  FETCH_IMAGES,
  FETCH_IMAGES_ERROR,
  FETCH_IMAGES_SUCCESS,
  GalleyImagesActionTypes,
} from '../types';

import { photosOrder } from './photosOrder';

photosOrder.sort((a, b) => a.order - b.order);

export const fetchImages = (): GalleyImagesActionTypes => ({ type: FETCH_IMAGES });

export const fetchImagesSuccess = (images: IImagesDate): GalleyImagesActionTypes => ({
  type: FETCH_IMAGES_SUCCESS,
  payload: images,
});

export const fetchImagesError = (): GalleyImagesActionTypes => ({
  type: FETCH_IMAGES_ERROR,
});

export enum SortDirections {
  UP = 1,
  DOWN = -1,
}

export type SortFiltersName = { partType: SortDirections; material: SortDirections };

const dynamicSort = <T>(
  property: keyof SortFiltersName,
  sortOrder: SortDirections = 1
) => {
  const prop = property as keyof T;
  return function (a: T, b: T) {
    var result = a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
    return result * sortOrder;
  };
};

const getSortedImages = ({
  imagesObj,
  filterName,
  direction,
}: {
  imagesObj: IImagesDate;
  filterName: keyof SortFiltersName;
  direction: SortDirections;
}): IImagesDate => {
  const { images, thumbnails } = imagesObj;
  const thumbnailsSorted = [...thumbnails];
  thumbnailsSorted.sort(dynamicSort(filterName, direction));
  const imagesSorted = thumbnailsSorted.reduce<IPhoto[]>(
    (imagesArray, currentThumbnail) => (
      imagesArray.push(
        images.find((image) => image.siblingCode === currentThumbnail.siblingCode)!
      ),
      imagesArray
    ),
    []
  );
  return { images: imagesSorted, thumbnails: thumbnailsSorted };
};

export const fetchGalleryImages: ActionCreator<
  ThunkAction<Promise<void>, IImagesDate, null, FetchImagesSuccessAction>
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchImages());

    const imagesData = await PageSvc.getPhotosData();
    if (imagesData) {
      // const { images, thumbnails } = imagesData;
      // const thumbnailsSorted = [...thumbnails];
      // thumbnailsSorted.sort(dynamicSort('material', -1));
      // thumbnailsSorted.sort(dynamicSort('type', -1));
      // const imagesSorted = thumbnailsSorted.reduce<IPhoto[]>(
      //   (imagesArray, currentThumbnail) => (
      //     imagesArray.push(
      //       images.find((image) => image.siblingCode === currentThumbnail.siblingCode)!
      //     ),
      //     imagesArray
      //   ),
      //   []
      // );

      let sortedImages = getSortedImages({
        imagesObj: imagesData,
        filterName: 'partType',
        direction: SortDirections.DOWN,
      });
      sortedImages = getSortedImages({
        imagesObj: sortedImages,
        filterName: 'material',
        direction: SortDirections.DOWN,
      });

      const thumbnailsSashWindows = sortedImages.thumbnails.filter(
        (thumbnail) => thumbnail.name.trim() === 'Sash window'
      );
      const thumbnailsWoodWindows = sortedImages.thumbnails.filter(
        (thumbnail) =>
          thumbnail.partType === 'windows' &&
          thumbnail.material === 'wood' &&
          thumbnail.name.trim() !== 'Sash window'
      );
      const restThumbnails = sortedImages.thumbnails.filter(
        (thumbnail) =>
          (thumbnail.partType !== 'windows' &&
            thumbnail.material !== 'wood' &&
            thumbnail.name.trim() !== 'Sash window') ||
          (thumbnail.partType === 'windows' &&
            thumbnail.material !== 'wood' &&
            thumbnail.name.trim() !== 'Sash window') ||
          thumbnail.partType !== 'windows'
      );
      let thumbnails = [
        ...thumbnailsWoodWindows,
        ...thumbnailsSashWindows,
        ...restThumbnails,
      ];

      //ordering function
      thumbnails = photosOrder.map(
        (photo) =>
          thumbnails.find((thubnail) => thubnail.url === photo.url) || thumbnails[0]
      );
      const images = thumbnails.reduce<IPhoto[]>(
        (imagesArray, currentThumbnail) => (
          imagesArray.push(
            sortedImages.images.find(
              (image) => image.siblingCode === currentThumbnail.siblingCode
            )!
          ),
          imagesArray
        ),
        []
      );
      sortedImages = {
        images,
        thumbnails,
      };

      dispatch(
        fetchImagesSuccess({
          ...sortedImages,
        })
      );
    }
  };
};

export const sortGalleryImages: ActionCreator<
  ThunkAction<
    void,
    {
      images: iGalleryImagesState;
    },
    null,
    FetchImagesSuccessAction
  >
> = ({
  filterName,
  direction,
}: {
  filterName: keyof SortFiltersName;
  direction: SortDirections;
}) => {
  return (
    dispatch: Dispatch,
    getState: () => CombinedState<{
      images: iGalleryImagesState;
    }>
  ) => {
    const {
      images: { images },
    } = getState();

    const filteredImages: IImagesDate = getSortedImages({
      imagesObj: images,
      filterName,
      direction,
    });

    dispatch(
      fetchImagesSuccess({
        ...filteredImages,
      })
    );
  };
};
