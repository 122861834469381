import { IImagesDate } from '../../../services/PageSvc';

export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_ERROR = 'FETCH_IMAGES_ERROR';
export const SET_SORTED_IMAGES = 'SET_SORTED_IMAGES';

export interface FetchImagesAction {
  type: typeof FETCH_IMAGES;
}

export interface FetchImagesSuccessAction {
  type: typeof FETCH_IMAGES_SUCCESS;
  payload: IImagesDate;
}

export interface FetchImagesErrorAction {
  type: typeof FETCH_IMAGES_ERROR;
}

export interface SetSortedImagesAction {
  type: typeof SET_SORTED_IMAGES;
  payload: IImagesDate;
}

export type GalleyImagesActionTypes =
  | FetchImagesAction
  | FetchImagesSuccessAction
  | FetchImagesErrorAction
  | SetSortedImagesAction;
