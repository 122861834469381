export const contact = {
  address: {
    street: '137 Chudleigh Road',
    district: 'Brockley',
    city: 'London',
    zip: 'SE4 1HP',
  },
  phones: ['07846422332', '02037743541'],
  email: 'tks.building@yahoo.com',
  companyName: 'TKS Building Company Ltd',
};
